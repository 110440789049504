function navTop() {
    if (Modernizr.touch) {
        var w = window.innerWidth;
    }
    else{
        var w = $(window).width();
    }

    if (w < 990) {
        $("#navSection").addClass("navbar-fixed-top");
    }
    else{
        $("#navSection").removeClass("navbar-fixed-top");
    }
}

    $(window).resize(function() {
        navTop();
    });
    $(window).scroll(function() {
        navTop();
    });

$(document).ready(function(){
   //INDEX PAGE SLIDER
   /* $('.bxslider').bxSlider({
        infiniteLoop: false,
        hideControlOnEnd: true,
        minSlides: 1,
        maxSlides: 3,
        slideMargin:60,
        slideWidth: 350,
        captions: true
    });*/
    //IE TOOLTIPPS
    if($('.gallery').length >0){
        $('a[data-rel^=lightcase]').lightcase({
            maxWidth: 3940,
            maxHeight: 2160,
            showSequenceInfo: false,
            overlayOpacity:.9
        });
    }

    $('input, textarea').placeholder();
    //PAGE MANU
    if (window.innerWidth <= 990) {
        $("#navSection").addClass("navbar-fixed-top");
    } else {
        $("#navSection").addClass("navbar-default");
    }
});