/*! modernizr 3.2.0 (Custom Build) | MIT *
 * http://modernizr.com/download/?-touchevents !*/
!function (e, n, t) {
    function o(e, n) {
        return typeof e === n
    }

    function s() {
        var e, n, t, s, a, i, r;
        for (var l in d)if (d.hasOwnProperty(l)) {
            if (e = [], n = d[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))for (t = 0; t < n.options.aliases.length; t++)e.push(n.options.aliases[t].toLowerCase());
            for (s = o(n.fn, "function") ? n.fn() : n.fn, a = 0; a < e.length; a++)i = e[a], r = i.split("."), 1 === r.length ? Modernizr[r[0]] = s : (!Modernizr[r[0]] || Modernizr[r[0]]instanceof Boolean || (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])), Modernizr[r[0]][r[1]] = s), f.push((s ? "" : "no-") + r.join("-"))
        }
    }

    function a(e) {
        var n = c.className, t = Modernizr._config.classPrefix || "";
        if (p && (n = n.baseVal), Modernizr._config.enableJSClass) {
            var o = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
            n = n.replace(o, "$1" + t + "js$2")
        }
        Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), p ? c.className.baseVal = n : c.className = n)
    }

    function i() {
        return "function" != typeof n.createElement ? n.createElement(arguments[0]) : p ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments)
    }

    function r() {
        var e = n.body;
        return e || (e = i(p ? "svg" : "body"), e.fake = !0), e
    }

    function l(e, t, o, s) {
        var a, l, f, d, u = "modernizr", p = i("div"), h = r();
        if (parseInt(o, 10))for (; o--;)f = i("div"), f.id = s ? s[o] : u + (o + 1), p.appendChild(f);
        return a = i("style"), a.type = "text/css", a.id = "s" + u, (h.fake ? h : p).appendChild(a), h.appendChild(p), a.styleSheet ? a.styleSheet.cssText = e : a.appendChild(n.createTextNode(e)), p.id = u, h.fake && (h.style.background = "", h.style.overflow = "hidden", d = c.style.overflow, c.style.overflow = "hidden", c.appendChild(h)), l = t(p, e), h.fake ? (h.parentNode.removeChild(h), c.style.overflow = d, c.offsetHeight) : p.parentNode.removeChild(p), !!l
    }

    var f = [], c = n.documentElement, d = [], u = {
        _version: "3.2.0",
        _config: {classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0},
        _q: [],
        on: function (e, n) {
            var t = this;
            setTimeout(function () {
                n(t[e])
            }, 0)
        },
        addTest: function (e, n, t) {
            d.push({name: e, fn: n, options: t})
        },
        addAsyncTest: function (e) {
            d.push({name: null, fn: e})
        }
    }, Modernizr = function () {
    };
    Modernizr.prototype = u, Modernizr = new Modernizr;
    var p = "svg" === c.nodeName.toLowerCase(), h = u._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : [];
    u._prefixes = h;
    var m = u.testStyles = l;
    Modernizr.addTest("touchevents", function () {
        var t;
        if ("ontouchstart"in e || e.DocumentTouch && n instanceof DocumentTouch)t = !0; else {
            var o = ["@media (", h.join("touch-enabled),("), "heartz", ")", "{#modernizr{top:9px;position:absolute}}"].join("");
            m(o, function (e) {
                t = 9 === e.offsetTop
            })
        }
        return t
    }), s(), a(f), delete u.addTest, delete u.addAsyncTest;
    for (var v = 0; v < Modernizr._q.length; v++)Modernizr._q[v]();
    e.Modernizr = Modernizr
}(window, document);